<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class='account'>
      <div class="box">
        <div class="tit">
          <p class="tit_left" v-if='inde==1'>{{tian_no}}核算科目</p>
          <ul class="tit_mianbao" v-if="inde==0">
            <li v-for="(i,index) in mianbao_biaoti" :style="mianbao_biaoti.length-1==index?'':'color:#8d52e3;'" @click="dianji_mianbaoxie(i,index)" :key="index">{{i.name}}<i class="el-icon-arrow-right"></i></li>
          </ul>
          <div class="tit_center">
            <div v-for="(i,index) in tit_list" :key='index' @click="tupian_dianji(index)">
              <img :src="index == inde?require('../../assets/red_quan.png'):require('../../assets/ccc_quan.png')"  alt="">
              <p :style="index == inde?'color:#8d52e3;':''">{{i}}</p>
            </div>
          </div>
        </div>
        <div :class="inde==1?'gongneng qiyong':'gongneng noqiyong'">
          <div class="left" v-if="inde==0">
            <p v-if="mianbao_biaoti.length!=1&&mianbao_biaoti[mianbao_biaoti.length-1].name!='税金'" @click="dianji_tianjia" style="background:#8d52e3;color:#fff;border:0.01rem solid #8d52e3;">添加</p>
            <!-- <p @click="dianji_daoru">导入</p> -->
            <p @click="dianji_daochu">导出</p>
            <!-- <p>调整顺序</p> -->
          </div>
          <div class="left" v-if="inde==1">
            <!-- <p @click="dianji_laji" style="background:#8d52e3;color:#fff;border:0.01rem solid #8d52e3;">停用科目</p> -->
          </div>
          <div v-if="mianbao_biaoti.length!=1" class="right">
            <p @click="dianji_shanchu_kemu">删除</p>
            <p v-if="inde==0" @click="dianji_tingyong_kemu">停用</p>
            <p v-if="inde==1" @click="dianji_qiyong_kemu">启用</p>
          </div>
        </div>
        <ul class="kemu_fenkai" v-if="mianbao_biaoti.length==1&&inde==0">
          <li v-for="(i,index) in tree_kemu" :key="index" @click="dianji_jinru_list(index,i)">
            <div><img src="../../assets/huang_wenjian.png" alt="">{{i}}</div>
            <span>添加明细</span>
          </li>
        </ul>
        <!-- <div class="kemu_tree">
            <el-tree :data="tree_kemu" :props="defaultProps">
                <div class="custom-tree-node"  slot-scope="{ node, data }">
                    <p :style="data.aim_prop==1?'color:#FFB466':'color:#1A2533'"><img src="../../assets/huang_wenjian.png" alt="">{{ node.label }}</p>
                    <p><span @click.stop='quxiao_tianjia_mubiao(data)' :style="data.aim_prop==1?'color:#A5C6FD':'color:#FFB466'">{{data.aim_prop==1?'取消目标':'添加目标'}}</span><span @click.stop='tingyong_qiyong_mubiao(data)' :style="data.active==1?'color:#A5C6FD':'color:#8d52e3'">{{data.active==1?'停用':'启用'}}</span></p>
                </div>
            </el-tree>
        </div> -->
        <el-table v-if="mianbao_biaoti.length!=1||this.inde==1" :cell-style="liebiao" :header-cell-style="biaotou" :data="hesuan_list" tooltip-effect="dark" style="width: 100%;" @selection-change="hang_change">
          <el-table-column type="selection" :selectable="shifou_xuanze" width="45">
          </el-table-column>
          <el-table-column prop="subject_code"  label="科目编码">
          </el-table-column>
          <el-table-column prop="subject_name"  label="科目名称">
          </el-table-column>
          <el-table-column prop="shuxing"  label="科目属性">
          </el-table-column>
          <el-table-column  label="备注">
            <template slot-scope="scope">
              <p style="width:1.45rem;border:0.01rem solid transparent;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
                {{scope.row.remarks}}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="" v-if="inde!=1">
            <template slot-scope="scope">
              <!-- {{scope.row}} -->
              <sapn v-if="scope.row.subject_name!='税金'&&scope.row.subject_name!='增值税及附加'&&scope.row.subject_name!='企业所得税'" @click="hangdian(scope.row)" style="font-size:0.12rem;color:#5997FF;display: inline-block;margin-right:0.2rem;cursor: pointer;">编辑</sapn>
              <sapn v-if="scope.row.subject_name!='增值税及附加'&&scope.row.subject_name!='企业所得税'" @click="jin_zi_mingxi(scope.row)" style="font-size:0.12rem;color:#5997FF;cursor: pointer;">{{scope.row.subject_name=='税金'?'进入子明细':'查看子明细'}}</sapn>
            </template>
          </el-table-column>
        </el-table>
        <el-drawer
            :visible.sync="drawer"
            :modal='false'
            :show-close='false'
            title="核算科目"
            size="620">
            <div style="color:#888888;font-size:0.14rem;height:0.42rem;width:94%;background:#E7E8EB;margin:0 auto;line-height:0.42rem;padding:0 0.12rem;min-width:3.88rem;">核算科目信息</div>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>科目编码
                </li>
                <li class="dd2" style="position: relative;"><input v-model="bianma" @focus="focu" @blur="blu" :disabled="bianxie" :style="bianxie?'background:#fff;color:#4C4A4D;':''" type="text" placeholder='请输入科目编码'>
                <p v-if="tishi_text" class="animated bounceInRight" style="position: absolute;right:0.54rem;font-size:0.12rem;bottom:-0.12rem;color:#e6a23c;" >该科目编码已存在</p>
                </li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>科目名称
                </li>
                <li class="dd2"><input v-model="name" type="text" placeholder='请输入科目名称'></li>
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    <span>*</span>科目属性
                </li>
                <li class="dd2"><input disabled="disabled" style="background:#fff;color:#4C4A4D;" v-model="mianbao_biaoti[mianbao_biaoti.length-1].name" type="text"></li>
                <!-- <li class="dd8">
                    <div v-for="(i,index) in jingfei_list" :key="index" @click="dianji_xuanze(index)">
                      <img :src="index == inde2?require('../../assets/shixin.png'):require('../../assets/kongxin.png')" alt="">
                      <p>{{i}}</p>
                    </div>
                </li> -->
            </ul>
            <ul class="liebiao">
                <li class="dd1">
                    备注
                </li>
                <li class="dd2">
                  <textarea class="shuoming_wenzi" type="text" maxlength="5000" v-model="beizhu_text" placeholder="请输入"></textarea>
                </li>
            </ul>
            <div class="xuan_foot">
                <div v-if="tianjia_or_xiugai==1" style="color:#fff;background:#8d52e3" @click="box_sure">添加</div>
                <div v-if="tianjia_or_xiugai==1" style="color:#fff;background:#8d52e3" @click="baocun_box_sure">保存并继续添加</div>
                <div v-if="tianjia_or_xiugai==2" style="color:#fff;background:#8d52e3" @click="xiugai_box_sure">确定</div>
                <div style="color:#888888;border:0.01rem solid #888888" @click="drawer = false">取消</div>
            </div>
        </el-drawer>
      </div>
    </div>
</template>

<script>
import { subject_have_exist, query_subject_code, query_ent_subject_list, query_subject_info_list, insert_subject_info, delete_subject_info, update_subject_active_batch, update_subject_info } from '../../api/api.js'
export default {
  name: 'account',
  data () {
    return {
      tian_no: '添加',
      inde: '0',
      inde2: null,
      tit_list: ['启用', '停用'],
      hesuan_list: [],
      ent_id: '',
      kuang: [],
      drawer: false,
      // ,'成本','税金'
      jingfei_list: ['收入', '固定经费', '变动经费'],
      bianma: '',
      name: '',
      kemu_id_zi: [],
      tianjia_or_xiugai: '1',
      danhang: '',
      // '成本','税金'
      tree_kemu: ['收入', '固定经费', '变动经费'],
      mianbao_biaoti: [
        {
          name: '核算科目',
          id: '0'
        }
      ],
      bianxie: false, // 科目编码是否能编写
      tishi_text: false,

      // 4-30
      beizhu_text: '' // 备注文本
    }
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
    if (sessionStorage.getItem('pc_mlbb_jichuxinxi') != null && sessionStorage.getItem('pc_mlbb_jichuxinxi') != undefined && sessionStorage.getItem('pc_mlbb_jichuxinxi') != '') {
      this.jichuxinxi = JSON.parse(JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi')))
    }
  },
  mounted () {
    // this.jichu()
  },
  watch: {
    drawer () {
      if (!this.drawer) {
        this.beizhu_text = ''
      }
    }
  },
  methods: {
    // 基础文字
    jichu () {
      let num = ''
      if (this.inde == 0) {
        num = '1'
      } else if (this.inde == 1) {
        num = '0'
      }
      const prop = '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].id
      query_subject_info_list({
        data: {
          ent_id: this.ent_id,
          active: num,
          subject_prop: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id,
          parent_id: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10213) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          for (let i = 0; i < date.length; i++) {
            // date[i]['shuxing']=this.mianbao_biaoti[this.mianbao_biaoti.length-1].name
            this.$set(date[i], 'shuxing', this.mianbao_biaoti[this.mianbao_biaoti.length - 1].name)
          }
          this.hesuan_list = date
        } else if (res.data.code == 10214) {}
      })
    },
    // 图片点击
    tupian_dianji (index) {
      this.inde = index
      if (index == 0) {
        this.jichu()
        this.tian_no = '添加'
      } else if (index == 1) {
        query_subject_info_list({
          data: {
            ent_id: this.$ent_id(),
            active: '0'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10213) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            for (let i = 0; i < date.length; i++) {
              // date[i]['shuxing']=this.mianbao_biaoti[this.mianbao_biaoti.length-1].name
              this.$set(date[i], 'shuxing', this.mianbao_biaoti[this.mianbao_biaoti.length - 1].name)
            }
            this.hesuan_list = date
          } else if (res.data.code == 10214) {}
        })
        this.tian_no = '停用'
      }
    },
    // 行点
    hangdian (val) {
      if (this.inde == 0) {
        this.tianjia_or_xiugai = 2
        console.log(val)
        this.drawer = true
        this.bianma = val.subject_code
        this.name = val.subject_name
        this.inde2 = Number(val.subject_prop) - 1 + ''
        this.danhang = val
        this.beizhu_text = val.remarks != null ? val.remarks : ''
        this.bianxie = true
      }
    },
    // 添加子明细
    jin_zi_mingxi (i) {
      let num = ''
      if (this.inde == 0) {
        num = '1'
      } else if (this.inde == 1) {
        num = '0'
      }
      console.log(i)
      query_subject_info_list({
        data: {
          ent_id: this.$ent_id(),
          active: num,
          subject_prop: i.subject_code,
          parent_id: i.subject_code
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10213) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.mianbao_biaoti.push({
            name: i.subject_name,
            id: i.subject_code,
            parent_id: i.subject_code
          })
          for (let i = 0; i < date.length; i++) {
            // date[i]['shuxing']=this.mianbao_biaoti[this.mianbao_biaoti.length-1].name
            this.$set(date[i], 'shuxing', this.mianbao_biaoti[this.mianbao_biaoti.length - 1].name)
          }
          this.hesuan_list = date
        } else if (res.data.code == 10214) {}
      })
    },
    // 多选框
    hang_change (val) {
      this.kuang = val
      console.log(9999999, val)
    },
    // 点击选择核算
    dianji_xuanze (index) {
      this.inde2 = index
    },
    // 聚焦
    focu () {
      this.tishi_text = false
    },
    // 失焦
    blu () {
      if (this.bianma.length > 0) {
        this.tishi()
      }
    },
    // 提示
    tishi () {
      // query_subject_code
      subject_have_exist({
        data: {
          ent_id: this.$ent_id(),
          subject_code: this.bianma
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 600) {
          this.tishi_text = true
        } else if (res.data.code == 700) {}
      })
    },
    // 点击确定
    box_sure () {
      if (!this.tishi_text && this.name != '' && this.inde2 != null) {
        let num = ''
        if (this.inde == 0) {
          num = '1'
        } else if (this.inde == 1) {
          num = '0'
        }
        const prop = '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].id
        insert_subject_info({
          data: {
            active: num,
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id,
            subject_code: this.bianma,
            subject_name: this.name,
            subject_prop: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id,
            parent_id: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id,
            remarks: this.beizhu_text.length != 0 ? this.beizhu_text : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10215) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.drawer = false
            this.jichu()
          } else if (res.data.code == 10216) {
            this.$message({
              message: '添加失败',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写信息',
          type: 'warning'
        })
      }
    },
    // 保存并继续
    baocun_box_sure () {
      if (this.bianma != '' && this.name != '' && this.inde2 != null) {
        let num = ''
        if (this.inde == 0) {
          num = '1'
        } else if (this.inde == 1) {
          num = '0'
        }
        const prop = '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].id
        insert_subject_info({
          data: {
            active: num,
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id,
            subject_code: this.bianma,
            subject_name: this.name,
            subject_prop: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id,
            parent_id: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id,
            remarks: this.beizhu_text.length != 0 ? this.beizhu_text : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10215) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.jichu()
            this.bianma = ''
            this.name = ''
            this.inde2 = '0'
          } else if (res.data.code == 10216) {
            this.$message({
              message: '添加失败',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请填写信息',
          type: 'warning'
        })
      }
    },
    // 点击添加
    dianji_tianjia () {
      this.bianma = ''
      this.name = ''
      this.inde2 = '0'
      this.tianjia_or_xiugai = 1
      this.drawer = true
      this.bianxie = false
    },
    // 点击停用返回启动页面
    dianji_laji () {
      this.inde = '0'
      this.jichu()
    },
    // 循环拿出科目的id
    kemu_id () {
      for (let i = 0; i < this.kuang.length; i++) {
        this.kemu_id_zi.push(this.kuang[i].subject_code)
      }
      this.kemu_id_zi = this.kemu_id_zi.join(',')
    },
    // 点击删除科目
    dianji_shanchu_kemu () {
      if (this.kuang.length != 0) {
        this.kemu_id()
        delete_subject_info({
          data: {
            ent_id: this.ent_id,
            user_id: this.jichuxinxi.user_id,
            subject_codes: this.kemu_id_zi
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10217) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.jichu()
            this.kemu_id_zi = []
            this.kuang = []
          } else if (res.data.code == 10218) {
            this.$message({
              message: '删除失败',
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请选择要删除的科目',
          type: 'error'
        })
      }
    },
    // 停用启用接口
    ting_qiyong () {
      this.kemu_id()
      update_subject_active_batch({
        data: {
          ent_id: this.ent_id,
          active: this.inde + '',
          subject_codes: this.kemu_id_zi,
          user_id: this.jichuxinxi.user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10207) {
          if (this.inde == 0) {
            this.$message({
              message: '停用成功',
              type: 'success'
            })
          } else if (this.inde == 1) {
            this.$message({
              message: '启用成功',
              type: 'success'
            })
          }
          this.jichu()
          this.kemu_id_zi = []
          this.kuang = []
        } else if (res.dta.code == 10208) {
          this.$message({
            message: '操作失败',
            type: 'error'
          })
        }
      })
    },
    // 点击停用科目
    dianji_tingyong_kemu () {
      if (this.kuang.length != 0) {
        this.ting_qiyong()
      } else {
        this.$message({
          message: '请选择要停用的科目',
          type: 'error'
        })
      }
    },
    // 点击启用
    dianji_qiyong_kemu () {
      if (this.kuang.length != 0) {
        this.ting_qiyong()
      } else {
        this.$message({
          message: '请选择要停用的科目',
          type: 'error'
        })
      }
    },
    // 修改确定
    xiugai_box_sure () {
      if (this.bianma != '' && this.name != '') {
        const prop = '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id
        update_subject_info({
          data: {
            id: this.danhang.id + '',
            active: '1',
            ent_id: this.ent_id,
            subject_code: this.bianma,
            subject_name: this.name,
            subject_prop: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id,
            parent_id: '' + this.mianbao_biaoti[this.mianbao_biaoti.length - 1].parent_id,
            remarks: this.beizhu_text.length != 0 ? this.beizhu_text : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10219) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.drawer = false
            this.jichu()
          } else if (res.data.code == 10220) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          }
        })
      }
    },
    // 点击导入
    dianji_daoru () {
      sessionStorage.setItem('pc_mlbb_piliangdaoru', '科目')
      this.$router.push('/imports')
    },
    // 点击导出
    dianji_daochu () {
      sessionStorage.setItem('pc_pc_pc_daochu', '科目')
      this.$router.push('/exportb')
    },
    // 选择啥科目打开列表
    dianji_jinru_list (index, i) {
      let num = ''
      if (this.inde == 0) {
        num = '1'
      } else if (this.inde == 1) {
        num = '0'
      }
      let zhi = ''
      if (index == 0) {
        zhi = '1'
      } else if (index == 1) {
        zhi = '2'
      } else if (index == 2) {
        zhi = '3'
      }
      query_subject_info_list({
        data: {
          ent_id: this.ent_id,
          active: num,
          subject_prop: zhi,
          parent_id: zhi
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10213) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.mianbao_biaoti.push({
            name: i,
            id: zhi,
            parent_id: zhi
          })
          for (let i = 0; i < date.length; i++) {
            // date[i]['shuxing']=this.mianbao_biaoti[this.mianbao_biaoti.length-1].name
            this.$set(date[i], 'shuxing', this.mianbao_biaoti[this.mianbao_biaoti.length - 1].name)
          }
          this.hesuan_list = date
        } else if (res.data.code == 10214) {}
      })
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'color:#4C4A4D;font-size:0.10rem;'
    },
    // 面包屑点击
    dianji_mianbaoxie (i, index) {
      if (this.mianbao_biaoti.length - 1 != index) {
        if (index == 0) {
          this.mianbao_biaoti.splice(1, this.mianbao_biaoti.length - 1)
          this.hesuan_list = []
        } else {
          let num = ''
          if (this.inde == 0) {
            num = '1'
          } else if (this.inde == 1) {
            num = '0'
          }
          console.log(i)
          query_subject_info_list({
            data: {
              ent_id: this.$ent_id(),
              active: num,
              subject_prop: i.parent_id,
              parent_id: i.parent_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 10213) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.mianbao_biaoti.splice(index + 1, this.mianbao_biaoti.length - 1)
              for (let i = 0; i < date.length; i++) {
                // date[i]['shuxing']=this.mianbao_biaoti[this.mianbao_biaoti.length-1].name
                this.$set(date[i], 'shuxing', this.mianbao_biaoti[this.mianbao_biaoti.length - 1].name)
              }
              this.hesuan_list = date
            } else if (res.data.code == 10214) {}
          })
        }
      }
    },
    // 是否可以选择
    shifou_xuanze (row, index) {
      // console.log(row)
      if (row.update_flag == 1) {
        return true
      } else if (row.update_flag == 0) {
        return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.account {
    height:100%;
    width:100%;
    position: relative;
    .box{
      padding: 0.36rem 0.3rem;
      .tit{
        display: flex;
        align-items:center;
        position: relative;
        .tit_left{
          font-size: 0.18rem;
          color:#1A2533;
        }
        .tit_mianbao{
          display: flex;
          align-items: center;
          li{
            font-size: 0.18rem;
            color:#1A2533;
            cursor: pointer;
          }
        }
        .tit_center{
          display: flex;
          align-items: center;
          margin-left: -0.68rem;
          left:50%;
          position: absolute;
          div{
            display: flex;
            align-items: center;
            margin-right: 0.27rem;
            cursor:pointer;
            img{
              height:0.18rem;
              width:0.18rem;
              margin-right: 0.04rem;
            }
            p{
              font-size: 0.12rem;
              color:#4C4A4D;
            }
          }
        }
      }
      .qiyong{
        background: #F7DEDE;
      }
      .noqiyong{
        background: #e7e8eb;
      }
      .gongneng{
        height:0.42rem;
        margin-top:0.10rem;
        padding: 0 0.12rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          display: flex;
          justify-content: left;
          align-items: center;
          p{
            padding: 0.04rem 0.12rem;
            border-radius: 0.02rem;
            font-size: 0.12rem;
            border:0.01rem solid #A9A9A9;
            margin-right: 0.14rem;
            background: #FFFFFF;
            color:#888888;
            cursor:pointer;
          }
        }
        .right{
          display: flex;
          justify-content: left;
          align-items: center;
          p{
            padding: 0.04rem 0.12rem;
            border-radius: 0.02rem;
            font-size: 0.12rem;
            border:0.01rem solid #A9A9A9;
            margin-right: 0.14rem;
            background: #FFFFFF;
            color:#888888;
            cursor:pointer;
          }
        }
      }
      .kemu_fenkai{
        li{
          display: flex;
          align-items: center;
          height:0.8rem;
          justify-content: space-between;
          border-bottom: 0.01rem solid #eee;
          padding: 0 0.18rem;
          cursor: pointer;
          &:hover{
            background:#eee;
          }
          div{
            display: flex;
            align-items: center;
            font-size: 0.14rem;
            color:#1A2533;
            img{
              height:0.18rem;
              width:0.22rem;
              margin-right:0.11rem;
            }
          }
          span{
            font-size: 0.12rem;
            color:#5997FF;
          }
        }
      }
    }
}
</style>
